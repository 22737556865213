import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    standalone: false
})
export class PaginationComponent implements OnInit {
    @Input() RPP?: number = 20;
    @Input() startRow?: number = 0;
    @Input() totalRows?: number = null;
    @Output() startRowChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    getPages() {
        const amount = this.totalRows / this.RPP;
        let pages;
        if (amount < 1) {
            pages = new Array(1);
        } else {
            pages = new Array(Math.ceil(amount));
        }
        for (var i = 0; i < pages.length; i++) {
            pages[i] = i + 1;
        }
        return pages;
    }

    getPage() {
        return Math.ceil(this.startRow / this.RPP) + 1;
    }

    truncate(i: number) {
        if (this.getPages().length <= 5) {
            // show 5 pages always
            return true;
        } else if (this.getPage() <= 3) {
            // more than 5 pages
            if (i <= 4) {
                return true;
            }
            return false;
        } else if (this.getPage() >= this.getPages().length - 2) {
            if (i >= this.getPages().length - 3) {
                return true;
            } else return false;
        } else {
            //longer than 7 => truncate all but curr & +/- 1
            if (i <= this.getPage() + 1 && i >= this.getPage() - 1) {
                return true;
            } else return false;
        }
    }

    showFill(first: boolean) {
        if (first) {
            if (this.getPage() > 3 && this.getPages().length > 5) {
                return true;
            }
            return false;
        } else {
            if (this.getPage() < this.getPages().length - 2 && this.getPages().length > 5) {
                return true;
            }
            return false;
        }
    }

    changePage(page: any) {
        if (!page) return;
        let res = null;
        switch (page) {
            case 'prev':
                res = this.startRow - this.RPP;
                break;
            case 'next':
                res = this.startRow + this.RPP;
                break;
            default:
                res = page * this.RPP - this.RPP;
                break;
        }
        this.startRowChange.emit(res);
    }
}
