import { Component, HostListener, OnInit } from '@angular/core';
import { ResourceService } from '../../services/resource.service';
import { HelpersService } from '../../services/helpers.service';
import { IndexService } from '../../services/index.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NewIndexSuccessComponent } from '../../components/modals/new-index-success/new-index-success.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
    selector: 'app-new-index',
    templateUrl: './new-index.component.html',
    styleUrls: ['./new-index.component.scss'],
    standalone: false
})
export class NewIndexComponent implements OnInit {
    formSTR: any = {};
    validation: any = {};
    weekInfo: any = {};
    inputWeekInfo: any = {};
    ready: boolean = false;
    changes: boolean = false;
    saving: boolean = false;
    editName: string = '';
    editTS: string = '';

    constructor(
        private ResourceService: ResourceService,
        private ModalService: NgbModal,
        private Router: Router,
        public IndexService: IndexService,
        public HelpersService: HelpersService,
        private AuthenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        const user: any = this.AuthenticationService.getUserValue();
        if (!user || !user.activities || !user.activities.length) {
            if (user.company) {
                this.Router.navigate([this.HelpersService.getUrl('register-activities')]);
            }
            return;
        }
        this.weekInfo = this.ResourceService.getWeekInfo();
        if (!this.weekInfo.input.active) {
            this.Router.navigate([this.HelpersService.getUrl('', 'home')]);
            return;
        }

        // get index
        this.IndexService.getDataInput().subscribe((next: any) => {
            this.formSTR = next.species.map((species) => {
                if (!species.parts) {
                    species.parts = [];
                }
                return {
                    ...species,
                    parts: species.parts.map((part) => {
                        const data = part.data || {};
                        data.fluctuation = data.fluctuation || 0;
                        data.amount = data.amount || 0;
                        if (data.price) {
                            data.price = data.price / 100;
                        }
                        if (data.prevPrice) {
                            data.prevPrice = data.prevPrice / 100;
                        }
                        return { ...part, data: data };
                    })
                };
            });
            this.inputWeekInfo = next.weekInfo;
            this.editName = next.editName;
            this.editTS = next.editTS;
            this.ready = true;
        });
    }

    submit() {
        this.saving = true;
        let FORM = { species: JSON.parse(JSON.stringify(this.formSTR)) };
        FORM.species = FORM.species.map((species) => {
            return {
                ...species,
                parts: species.parts.map((part) => {
                    const data = part.data || {};
                    if (data.price) {
                        data.price = Math.round(data.price * 100);
                    }
                    return {
                        ...part,
                        data: data
                    };
                })
            };
        });
        this.IndexService.updateDataInput(FORM).subscribe({
            next: (next) => {
                this.changes = false;
                this.validation = {};
                this.saving = false;
                this.Router.navigate([this.HelpersService.getUrl('', 'home')]);
                const modalRef = this.ModalService.open(NewIndexSuccessComponent, {
                    windowClass: 'main-modal login-modal'
                });
            },
            error: (error) => {
                this.saving = false;
                this.validation = error.error.details.species;
            }
        });
    }

    handleAmountChange(data) {
        if (!data.amount) {
            data.fluctuation = 0;
            this.IndexService.handleChangeFluctuation(data);
        }
    }

    // validation obj for part row
    getValidation(speciesIndex: number, partIndex: number) {
        if (
            this.validation &&
            this.validation[speciesIndex] &&
            this.validation[speciesIndex].parts &&
            this.validation[speciesIndex].parts[partIndex] &&
            this.validation[speciesIndex].parts[partIndex].data
        ) {
            return (
                this.validation &&
                this.validation[speciesIndex] &&
                this.validation[speciesIndex].parts &&
                this.validation[speciesIndex].parts[partIndex] &&
                this.validation[speciesIndex].parts[partIndex].data
            );
        } else return {};
    }

    removeValidation(speciesIndex: number, partIndex: number) {
        if (
            this.validation &&
            this.validation[speciesIndex] &&
            this.validation[speciesIndex].parts &&
            this.validation[speciesIndex].parts[partIndex] &&
            this.validation[speciesIndex].parts[partIndex].data
        ) {
            this.validation[speciesIndex].parts[partIndex].data = {};
        }
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
