import { Component, EventEmitter, HostListener, Input, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../../utils/pipes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../../services/resource.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { HelpersService } from '../../../services/helpers.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-register-form',
    templateUrl: './register-form.component.html',
    styleUrls: ['./register-form.component.scss'],
    standalone: false
})
export class RegisterFormComponent implements OnInit {
    @Input() formType: string = 'register';
    @Input() detail: 'profile' | null = null;
    @Input() FORM: any = {};
    @Input() READONLYFORM: any = {};
    @Input() validation: any = {};
    @Input() saving: boolean = false;
    @Input() admin: boolean = false;
    @Input() activeSection: 'company' | 'user' | 'password' | '' | null = '';
    @Output() editSection = new EventEmitter();
    @Output() saveSection = new EventEmitter();
    @Output() setInactive = new EventEmitter();

    ResourceService = inject(ResourceService);

    pwdIsValid: boolean = false;
    oldPwdIsValid: boolean = false;
    newPwdIsValid: boolean = false;
    languageOptions: any[] = this.ResourceService.getLanguages();
    federations: any[] = this.ResourceService.getFederations();

    user: any;

    constructor(
        public TranslatePipe: TranslatePipe,
        private ModalService: NgbModal,
        private Router: Router,
        public HelpersService: HelpersService,
        private AuthenticationService: AuthenticationService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.federations.push({ title: this.TranslatePipe.transform('register_no_federation'), value: 'none' });
        this.AuthenticationService.getUser().subscribe((next: any) => {
            if (next) {
                this.user = { ...next };
            }
        });
    }

    // // MOVE TO PARENT, CHECK REGISTRATION FORM
    // resetForm() {
    //     this.editSection.emit('');
    //     if (this.READONLYFORM) {
    //         this.FORM = JSON.parse(JSON.stringify(this.READONLYFORM));
    //     }
    // }

    handleCheckFederation(evt) {
        if (evt == 'none') {
            this.FORM.company.federationIds.indexOf('none') != -1
                ? (this.FORM.company.federationIds = [])
                : (this.FORM.company.federationIds = ['none']);
            return;
        }
        let newModel = [...this.FORM.company.federationIds];
        if (newModel.indexOf('none') != -1) {
            newModel.splice(newModel.indexOf('none'), 1);
        }
        const index = newModel.indexOf(evt);
        if (index != -1) {
            newModel.splice(index, 1);
        } else newModel.push(evt);
        this.FORM.company.federationIds = newModel;
    }
}
