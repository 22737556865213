import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../../services/authentication.service';
import { HelpersService } from '../../../services/helpers.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '../../../utils/pipes';

declare const grecaptcha: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
    loginSTR: any = {};
    forgotPasswordSTR: any = {};
    validation: any = {};
    backto: string = '';
    saving: boolean = false;
    toggleForgotPassword: boolean = false;

    constructor(
        public ActiveModal: NgbActiveModal,
        private AuthenticationService: AuthenticationService,
        public HelpersService: HelpersService,
        private toastr: ToastrService,
        private TranslatePipe: TranslatePipe
    ) {}

    ngOnInit(): void {}

    submit() {
        let that = this;
        this.saving = true;
        grecaptcha.ready(function () {
            grecaptcha.execute('6LfXbsQaAAAAAIpbJZelj4SW37Md5HVBNHvP0kns', { action: 'submit' }).then(function (token) {
                that.AuthenticationService.login(that.loginSTR, token).subscribe({
                    next: (next: any) => {
                        that.validation = {};
                        setTimeout(() => {
                            that.AuthenticationService.fetchUser().subscribe((next) => {
                                if (!next.company) {
                                    window.location.href = that.HelpersService.getUrl(
                                        'select-company',
                                        undefined,
                                        that.backto
                                    );
                                } else
                                    window.location.href = that.backto || that.HelpersService.getUrl(that.backto || '');
                            });
                        }, 1);
                    },
                    error: (error) => {
                        that.saving = false;
                        if (error.error.error && error.error.error == 'captcha_verification') {
                            that.validation = {
                                captcha: 'generic_error_captcha_score'
                            };
                        } else that.validation = error.error.details;
                    }
                });
            });
        });
    }

    forgotPassword() {
        this.saving = true;
        this.AuthenticationService.forgotPassword(this.forgotPasswordSTR).subscribe({
            next: (next) => {
                this.validation = {};
                this.toastr.success(
                    this.TranslatePipe.transform('toast_forgot_password_success_msg'),
                    this.TranslatePipe.transform('toast_forgot_password_success_title')
                );
                this.toggleForgotPassword = false;
                this.saving = false;
            },
            error: (error) => {
                this.validation = error.error.details;
                this.saving = false;
            }
        });
    }
}
