import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '../../../services/config.service';
import { User } from '../../../utils/api';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    standalone: false
})
export class AvatarComponent implements OnInit {
    @Input() user?: User;
    @Input() image?: string;
    @Input() title?: string;
    @Input() subtitle?: string;
    @Input() size?: number = 32;
    @Input() fontSize?: number = 13;
    @Input() shadowSize?: number;

    constructor(private ConfigService: ConfigService) {}

    ngOnInit(): void {}

    getInitials() {
        if (this.user) {
            return `${this.user.firstname.trim().charAt(0)}${this.user.lastname.trim().charAt(0)}${
                this.ConfigService.getConfig().avatarExtendInitials
                    ? this.user.lastname.trim().charAt(this.user.lastname.trim().length - 1)
                    : ''
            }`.toUpperCase();
        }
    }

    getBackgroundImage() {
        if (!this.user || (!this.user.firstname && !this.user.lastname)) {
            if (this.image) return `url(${this.image})`;
            if (!this.image) return `url(${'/assets/img/misc/avatar-placeholder.jpg'})`;
        }
    }
}
