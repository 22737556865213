import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../utils/pipes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../services/resource.service';
import { AuthenticationService } from '../../services/authentication.service';
import { UsersService } from '../../services/users.service';
import { HelpersService } from '../../services/helpers.service';
import { TranslatorService } from '../../services/translator.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-firstlogin',
    templateUrl: './firstlogin.component.html',
    styleUrls: ['./firstlogin.component.scss'],
    standalone: false
})
export class FirstloginComponent implements OnInit {
    id: string = '';
    hash: string = '';
    formSTR: any = {};
    validation: any = { user: {}, company: {} };
    saving: boolean = false;
    ready: boolean = false;
    changes: boolean = false;

    constructor(
        public TranslatePipe: TranslatePipe,
        private ResourceService: ResourceService,
        public HelpersService: HelpersService,
        private UsersService: UsersService,
        private Route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.id = this.Route.snapshot.params['id'];
        this.hash = this.Route.snapshot.params['hash'];

        // get user hash
        this.UsersService.getUserForHash(this.id, this.hash).subscribe({
            next: (next) => {
                setTimeout(() => {
                    this.formSTR = {
                        ...next,
                        company: {
                            ...next.company,
                            federations: this.ResourceService.getUserFederations(next)
                                .map((item) => {
                                    return item.name;
                                })
                                .join(', ')
                        },
                        user: {
                            firstname: next.firstname || '',
                            lastname: next.lastname || '',
                            language: next.language || '',
                            mobile: next.mobile || '',
                            email: next.email || '',
                            password: ''
                        }
                    };
                    this.ready = true;
                }, 500);
            },
            error: (error) => {
                window.location.href = `${this.HelpersService.getUrl('', 'home')}?msg=invalidlink`;
            }
        });
    }

    submit() {
        let FORM = { ...this.formSTR.user };
        if (FORM.mobile == '') {
            delete FORM.mobile;
        }
        this.saving = true;
        this.UsersService.firstlogin(this.id, this.hash, FORM).subscribe({
            next: (next) => {
                this.changes = false;
                this.validation = { user: {}, company: {} };
                this.saving = false;
                window.location.href = this.HelpersService.getUrl('', 'home');
            },
            error: (error) => {
                this.validation = { user: {}, company: {} };
                this.validation.user = error.error.details || {};
                this.saving = false;
            }
        });
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
