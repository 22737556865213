import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-array-tooltip-display',
    templateUrl: './array-tooltip-display.component.html',
    styleUrls: ['./array-tooltip-display.component.scss'],
    standalone: false
})
export class ArrayTooltipDisplayComponent implements OnInit {
    @Input() items: string[] = [];

    constructor() {}

    ngOnInit(): void {}

    getTip() {
        if (!Array.isArray(this.items)) return '';
        return this.items.slice(1, this.items.length).join(', ');
    }

    getItems() {
        if (!Array.isArray(this.items)) return [];
        return this.items ? this.items : [];
    }
}
