import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../utils/pipes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../services/resource.service';
import { AuthenticationService } from '../../services/authentication.service';
import { HelpersService } from '../../services/helpers.service';
import { UsersService } from '../../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { AddUserComponent } from '../../components/modals/add-user/add-user.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    standalone: false
})
export class UsersComponent implements OnInit {
    formSTR: any = {};
    validation: any = {};
    saving: boolean = false;
    ready: boolean = false;
    changes: boolean = false;
    users: any[] = [];

    TranslatePipe = inject(TranslatePipe);

    tableHeads: any = [
        { name: this.TranslatePipe.transform('users_name'), code: 'name', sortable: false },
        { name: this.TranslatePipe.transform('users_email'), code: 'email', sortable: false },
        { name: this.TranslatePipe.transform('users_language'), code: 'language', sortable: false },
        { name: '', code: 'actions' }
    ];

    constructor(
        private ModalService: NgbModal,
        private Router: Router,
        private ResourceService: ResourceService,
        public HelpersService: HelpersService,
        private AuthenticationService: AuthenticationService,
        private UsersService: UsersService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.getUsers();
    }

    getUsers() {
        this.ready = false;
        this.UsersService.getUsers().subscribe((next: any) => {
            this.users = next.map((user) => {
                const actions = [];
                if (!this.AuthenticationService.isCurrentUserId(user.id)) {
                    const deleteAction = {
                        name: this.TranslatePipe.transform('users_action_delete'),
                        code: 'delete',
                        icon: 'trash',
                        class: 'delete-red',
                        confirmDelete: true
                    };
                    actions.push(deleteAction);
                }
                if (user.firstlogin) {
                    const firstloginAction = {
                        name: this.TranslatePipe.transform('users_send_firstlogin'),
                        code: 'firstlogin',
                        icon: 'reset-lock'
                    };
                    actions.push(firstloginAction);
                }
                if (!user.firstlogin && !this.AuthenticationService.isCurrentUserId(user.id)) {
                    const resetPasswordAction = {
                        name: this.TranslatePipe.transform('users_reset_password'),
                        code: 'resetpassword',
                        icon: 'reset-lock'
                    };
                    actions.push(resetPasswordAction);
                }
                const stamps = [];
                if (user.firstlogin) {
                    stamps.push({
                        value: this.TranslatePipe.transform('users_processing'),
                        classList: 'stamp--orange'
                    });
                }
                if (user.companyMainUser) {
                    stamps.push({
                        value: this.TranslatePipe.transform('users_main_user'),
                        classList: 'stamp--blue'
                    });
                }
                let name = `${user.firstname || ''} ${user.lastname || ''}`;
                !user.firstname && !user.lastname ? (name = '') : '';
                return {
                    ...user,
                    // name: !user.firstlogin
                    //     ? `${user.firstname} ${user.lastname}`
                    //     : this.TranslatePipe.transform('users_processing'),
                    name: {
                        type: 'default',
                        value: name,
                        stamps: stamps
                    },
                    language: this.ResourceService.getLanguageByCode(user.language).title,
                    languageCode: user.language,
                    actions: {
                        type: 'actions',
                        actions: actions
                    }
                };
            });
            this.ready = true;
        });
    }

    addUser() {
        const modalRef = this.ModalService.open(AddUserComponent, {
            windowClass: 'main-modal adduser-modal'
        });
        modalRef.componentInstance.created.subscribe((next) => {
            this.getUsers();
        });
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'firstlogin':
                this.UsersService.forgotPassword(item.email).subscribe((next) => {
                    this.toastr.success(
                        this.TranslatePipe.transform('toast_user_resent_firstlogin_success_msg'),
                        this.TranslatePipe.transform('toast_user_resent_firstlogin_success_title')
                    );
                });
                break;
            case 'resetpassword':
                this.UsersService.forgotPassword(item.email).subscribe((next) => {
                    this.toastr.success(
                        this.TranslatePipe.transform('toast_user_forgot_password_success_msg'),
                        this.TranslatePipe.transform('toast_user_forgot_password_success_title')
                    );
                });
                break;
            case 'delete':
                this.UsersService.deleteUser(item.id).subscribe((next) => {
                    this.toastr.success(
                        this.TranslatePipe.transform('toast_delete_user_success_msg'),
                        this.TranslatePipe.transform('toast_delete_user_success_title')
                    );
                    this.getUsers();
                });
                break;
        }
    }
}
