import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { HelpersService } from '../../services/helpers.service';

@Component({
    selector: 'app-pick-company',
    templateUrl: './pick-company.component.html',
    styleUrls: ['./pick-company.component.scss'],
    standalone: false
})
export class PickCompanyComponent implements OnInit {
    companies: any[] = [];

    constructor(private AuthenticationService: AuthenticationService, private HelpersService: HelpersService) {}

    ngOnInit(): void {
        this.companies = this.AuthenticationService.getUserValue()['companies'];
    }

    pickCompany(id) {
        const backto = this.HelpersService.getParam('backto');
        this.AuthenticationService.selectCompany(id, backto);
    }
}
