import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';
import { DefaultService } from '../../utils/api';

@Component({
    selector: 'app-subscription-cancelled',
    templateUrl: './subscription-cancelled.component.html',
    styleUrls: ['./subscription-cancelled.component.scss'],
    standalone: false
})
export class SubscriptionCancelledComponent implements OnInit {
    id: string = '';
    hash: string = '';
    saving: boolean = false;
    successState: boolean = false;

    constructor(
        public HelpersService: HelpersService,
        public Router: Router,
        public Route: ActivatedRoute,
        public DefaultService: DefaultService
    ) {}

    ngOnInit(): void {
        this.id = this.Route.snapshot.params['id'];
        this.hash = this.Route.snapshot.params['hash'];
    }

    submit() {
        this.saving = true;
        this.DefaultService.subscriptionCancelSubscription(this.id, this.hash).subscribe({
            next: (next) => {
                setTimeout(() => {
                    this.saving = false;
                    this.successState = true;
                }, 500);
            },
            error: (error) => {
                this.Router.navigate([this.HelpersService.getUrl('', 'home')]);
            }
        });
    }
}
