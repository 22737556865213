import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApiInterceptor } from './utils/interceptors';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldTextComponent } from './components/fields/field-text/field-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldToggleComponent } from './components/fields/field-toggle/field-toggle.component';
import { FieldTextareaComponent } from './components/fields/field-textarea/field-textarea.component';
import { SpinnerComponent } from './components/misc/spinner/spinner.component';
import { ApiModule, Configuration } from './utils/api';
import { AvatarComponent } from './components/common/avatar/avatar.component';
import { PopoutPanelComponent } from './components/layout/popout-panel/popout-panel.component';
import { FieldSwitchComponent } from './components/fields/field-switch/field-switch.component';
import { FilterButtonComponent } from './components/common/filter-button/filter-button.component';
import { TableComponent } from './components/common/table/table.component';
import { PopoverComponent } from './components/common/popover/popover.component';
import { ClickOutsideDirective } from './utils/directives';
import {
    displayPipe,
    centPipe,
    formatNumberPipe,
    toFixedPipe,
    vatPipe,
    formatTsPipe,
    customCurrencyPipe,
    percentagePipe,
    TranslatePipe,
    numberPipe,
    absPipe,
    leadingZerosPipe
} from './utils/pipes';
import { FilterButtonGroupComponent } from './components/common/filter-button-group/filter-button-group.component';
import { EmptyStateComponent } from './components/common/empty-state/empty-state.component';
import { GlobalErrorHandler } from './utils/errorHandler';
import { ModalSidePanelComponent } from './components/layout/modal-side-panel/modal-side-panel.component';
import { SideNavigationComponent } from './components/common/side-navigation/side-navigation.component';
import { FieldSelectComponent } from './components/fields/field-select/field-select.component';
import { ShimmerComponent } from './components/misc/shimmer/shimmer.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ModeSwitchComponent } from './components/misc/mode-switch/mode-switch.component';
import { PaginationComponent } from './components/misc/pagination/pagination.component';
import { ConfirmDeleteComponent } from './components/misc/confirm-delete/confirm-delete.component';
import { FormComponent } from './components/common/paged-form/paged-form.component';
import { FieldHorizontalRadioComponent } from './components/fields/field-horizontal-radio/field-horizontal-radio.component';
import { FieldRadiosComponent } from './components/fields/field-radios/field-radios.component';
import { FieldTypeaheadComponent } from './components/fields/field-typeahead/field-typeahead.component';
import { FieldDateComponent } from './components/fields/field-date/field-date.component';
import { FieldCurrencyComponent } from './components/fields/field-currency/field-currency.component';
import { InlineSpinnerComponent } from './components/misc/inline-spinner/inline-spinner.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LoginComponent } from './components/modals/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { FieldCheckboxesComponent } from './components/fields/field-checkboxes/field-checkboxes.component';
import { FieldPasswordCheckComponent } from './components/fields/field-password-check/field-password-check.component';
import { RegisterSuccessComponent } from './components/modals/register-success/register-success.component';
import { NewIndexComponent } from './pages/new-index/new-index.component';
import { NewIndexSuccessComponent } from './components/modals/new-index-success/new-index-success.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SubscriptionConfirmedComponent } from './pages/subscription-confirmed/subscription-confirmed.component';
import { RegisterActivitiesComponent } from './pages/register-activities/register-activities.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { RegisterFormComponent } from './components/forms/register-form/register-form.component';
import { PartsComponent } from './pages/parts/parts.component';
import { UsersComponent } from './pages/users/users.component';
import { AddUserComponent } from './components/modals/add-user/add-user.component';
import { FirstloginComponent } from './pages/firstlogin/firstlogin.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SubscriptionCancelledComponent } from './pages/subscription-cancelled/subscription-cancelled.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { TermsComponent } from './pages/terms/terms.component';
import { RegistrationHistoryComponent } from './pages/registration-history/registration-history.component';
import { RegistrationHistoryDetailComponent } from './pages/registration-history-detail/registration-history-detail.component';
import { PickCompanyComponent } from './pages/pick-company/pick-company.component';
import { AdminCompanyComponent } from './pages/admin/admin-company/admin-company.component';
import { AdminUsersComponent } from './pages/admin/admin-users/admin-users.component';
import { AdminSubscriptionsComponent } from './pages/admin/admin-subscriptions/admin-subscriptions.component';
import { ArrayTooltipDisplayComponent } from './components/common/array-tooltip-display/array-tooltip-display.component';
import { SlaughterhouseMonitorComponent } from './pages/slaughterhouse-monitor/slaughterhouse-monitor.component';
import { AdminCompaniesComponent } from './pages/admin/admin-companies/admin-companies.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SpinnerComponent,
        FieldTextComponent,
        FieldToggleComponent,
        FieldTextareaComponent,
        AvatarComponent,
        PopoutPanelComponent,
        FieldSwitchComponent,
        FilterButtonComponent,
        TableComponent,
        PopoverComponent,
        ClickOutsideDirective,
        formatTsPipe,
        displayPipe,
        centPipe,
        formatNumberPipe,
        toFixedPipe,
        vatPipe,
        TranslatePipe,
        customCurrencyPipe,
        leadingZerosPipe,
        percentagePipe,
        numberPipe,
        absPipe,
        FilterButtonGroupComponent,
        EmptyStateComponent,
        ModalSidePanelComponent,
        SideNavigationComponent,
        FieldSelectComponent,
        ShimmerComponent,
        ModeSwitchComponent,
        PaginationComponent,
        ConfirmDeleteComponent,
        FormComponent,
        FieldHorizontalRadioComponent,
        FieldRadiosComponent,
        FieldTypeaheadComponent,
        FieldDateComponent,
        FieldCurrencyComponent,
        InlineSpinnerComponent,
        HomeComponent,
        AboutComponent,
        ContactComponent,
        LoginComponent,
        RegisterComponent,
        FieldCheckboxesComponent,
        FieldPasswordCheckComponent,
        RegisterSuccessComponent,
        NewIndexComponent,
        NewIndexSuccessComponent,
        ProfileComponent,
        SubscriptionConfirmedComponent,
        RegisterActivitiesComponent,
        PrivacyPolicyComponent,
        RegisterFormComponent,
        PartsComponent,
        UsersComponent,
        AddUserComponent,
        FirstloginComponent,
        ForgotPasswordComponent,
        SubscriptionCancelledComponent,
        CookiesComponent,
        TermsComponent,
        RegistrationHistoryComponent,
        RegistrationHistoryDetailComponent,
        PickCompanyComponent,
        AdminCompaniesComponent,
        AdminCompanyComponent,
        AdminUsersComponent,
        AdminSubscriptionsComponent,
        ArrayTooltipDisplayComponent,
        SlaughterhouseMonitorComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-bottom-right',
            closeButton: true,
            // disableTimeOut: true,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        }),
        NgxMaskDirective,
        NgxMaskPipe
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        numberPipe,
        formatTsPipe,
        provideNgxMask(null),
        provideHttpClient(withInterceptors([ApiInterceptor]))
    ]
})
export class AppModule {}
