import { Component, OnInit } from '@angular/core';
import { DefaultService } from '../../utils/api';
import { HelpersService } from '../../services/helpers.service';

declare const grecaptcha: any;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: false
})
export class ContactComponent implements OnInit {
    formSTR: any = { message: '' };
    validation: any = {};
    saving: boolean = false;
    successState: boolean = false;

    constructor(
        private DefaultService: DefaultService,
        public HelpersService: HelpersService
    ) {}

    ngOnInit(): void {}

    submit() {
        let FORM = structuredClone(this.formSTR);
        let that = this;
        this.saving = true;
        grecaptcha.ready(function () {
            grecaptcha.execute('6LfXbsQaAAAAAIpbJZelj4SW37Md5HVBNHvP0kns', { action: 'submit' }).then(function (token) {
                that.DefaultService.contactSaveContact(FORM, token).subscribe({
                    next: (next) => {
                        that.saving = false;
                        that.validation = {};
                        that.formSTR = {};
                        that.successState = true;
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                        }, 1);
                    },
                    error: (error) => {
                        that.saving = false;
                        if (error.error.error && error.error.error == 'captcha_verification') {
                            that.validation = {
                                captcha: 'generic_error_captcha_score'
                            };
                        } else that.validation = error.error.details;
                    }
                });
            });
        });
    }
}
