import { Pipe, PipeTransform } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TranslatorService } from '../services/translator.service';
import 'moment/locale/nl-be';
import moment from 'moment';
moment.locale('nl-be');

const keywordAt = 'om';
const keywordToday = 'vandaag';
const today = moment();

@Pipe({
    name: 'formatTs',
    standalone: false
})
export class formatTsPipe implements PipeTransform {
    transform(ts: string, format: string): string {
        if (!ts || !format || !moment(ts).isValid()) {
            return ts || '-';
        }
        switch (format) {
            case 'prettyTs':
                return moment(ts).calendar(null, {
                    sameElse: 'DD-MM-YYYY'
                });
                break;

            default:
                break;
        }
        return moment(ts).format(format);
    }
}

@Pipe({
    name: 'display',
    standalone: false
})
export class displayPipe implements PipeTransform {
    transform(value: any, type?): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        } else return value;
    }
}

@Pipe({
    name: 'cent',
    standalone: false
})
export class centPipe implements PipeTransform {
    transform(value: any): any {
        if (value == undefined || value == null) {
            return '-';
        }
        value = Math.abs(value);
        if (value === 0) {
            return '0,00';
        }
        return (value / 100).toFixed(2).toString().replace('.', ',');
    }
}

@Pipe({
    name: 'abs',
    standalone: false
})
export class absPipe implements PipeTransform {
    transform(num: number, args?: any): any {
        return Math.abs(num);
    }
}

@Pipe({
    name: 'formatNumber',
    standalone: false
})
export class formatNumberPipe implements PipeTransform {
    transform(value: any): any {
        if (value == undefined || value == null) {
            return '-';
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}

@Pipe({
    name: 'toFixed',
    standalone: false
})
export class toFixedPipe implements PipeTransform {
    transform(value: any, rd: number): any {
        if (value == undefined || value == null) {
            return '-';
        }
        return value
            .toFixed(rd || 1)
            .toString()
            .replace('.', ',');
    }
}

@Pipe({
    name: 'vat',
    standalone: false
})
export class vatPipe implements PipeTransform {
    // (BE 0###.###.###)
    transform(value: any, type?): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }
        return `${value.substring(0, 2)} ${value.substring(2, 6)}.${value.substring(6, 9)}.${value.substring(9, 12)}`;
    }
}

@Pipe({
    name: 'customCurrency',
    standalone: false
})
export class customCurrencyPipe implements PipeTransform {
    constructor(private cp: CurrencyPipe) {}
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '€ 0,00';
        }
        if (!value) {
            return '-';
        } else {
            // // float
            // if (value % 1 !== 0) {
            //     console.log('float');
            // }
            // // number
            // if (value % 1 === 0) {
            //     console.log('number');
            // }
            let returnValue = this.cp.transform(parseFloat(value).toString(), '€ ').replace(/,/g, '.');
            const n = returnValue.lastIndexOf('.');
            if (n >= 0 && returnValue.length) {
                returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
            }
            return returnValue;
        }
    }
}

@Pipe({
    name: 'customPercentage',
    standalone: false
})
export class percentagePipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '0%';
        }
        if (!value) {
            return '-';
        } else return value.toString().replace('.', ',') + '%';
    }
}

@Pipe({
    name: 'customNumber',
    standalone: false
})
export class numberPipe implements PipeTransform {
    transform(value: any): any {
        if (!value || value === 0 || value === '0') {
            return '0';
        }
        value = value.toString().replace('.', ',');
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}

@Pipe({
    name: 'translate',
    standalone: false
})
export class TranslatePipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) {}
    public transform(key: string): string {
        return this.TranslatorService.getTranslation(key);
    }
}

@Pipe({
    name: 'leadingZeros',
    standalone: false
})
export class leadingZerosPipe implements PipeTransform {
    constructor() {}
    transform(number: number | string, size: number): string {
        return number.toString().padStart(size, '0');
    }
}
