import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResourceService } from '../../../services/resource.service';
import { SearchService } from '../../../services/search.service';
import { DefaultService } from '../../../utils/api';
import { formatTsPipe, TranslatePipe } from '../../../utils/pipes';
import { HelpersService } from '../../../services/helpers.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
    selector: 'app-admin-companies',
    templateUrl: './admin-companies.component.html',
    styleUrls: ['./admin-companies.component.scss'],
    standalone: false
})
export class AdminCompaniesComponent implements OnInit {
    items: any[] = null;
    itemsToAccept: any[] = null;

    ready: boolean = false;

    user: any;

    RPP: number = 20;
    RPPToAccept: number = 20;
    startRow: number = 0;
    startRowToAccept: number = 0;
    totalRows: number = null;
    totalRowsToAccept: number = null;
    tableLoading: boolean = true;
    tableLoadingToAccept: boolean = true;
    SORT: any = {};
    SEARCH: any = {};
    TranslatePipe = inject(TranslatePipe);
    ResourceService = inject(ResourceService);
    options: any = {
        activity: [
            {
                title: this.TranslatePipe.transform('activity_buyer'),
                value: 'buyer'
            },
            {
                title: this.TranslatePipe.transform('activity_provider'),
                value: 'provider'
            }
        ],
        status: [
            {
                title: this.TranslatePipe.transform('status_1'),
                value: 1
            },
            {
                title: this.TranslatePipe.transform('status_3'),
                value: 3
            },
            {
                title: this.TranslatePipe.transform('status_4'),
                value: 4
            }
        ],
        species: this.ResourceService.getSpeciesPartsCodes().map((item) => {
            return { title: this.TranslatePipe.transform('species_' + item), value: item };
        })
    };
    companiesToAcceptLoaded: boolean = false;

    /*
        status
        1 -> actief
        2 -> in aanvraag
        3 -> gearchiveerd
        4 -> afgewezen
    */

    previousParams: any = null;

    tableHeads: any = [
        { width: '28%', name: this.TranslatePipe.transform('admincompanies_th_name'), code: 'name', sortable: false },
        { width: '16%', name: this.TranslatePipe.transform('admincompanies_th_city'), code: 'city', sortable: false },
        { width: '20%', name: this.TranslatePipe.transform('admincompanies_th_type'), code: 'type', sortable: false },
        {
            width: '14%',
            name: this.TranslatePipe.transform('admincompanies_th_status'),
            code: 'statusDisplay',
            sortable: false
        },
        {
            width: '',
            name: this.TranslatePipe.transform('admincompanies_th_input_ts'),
            code: 'lastInputTS',
            sortable: true
        }
    ];
    tableHeadsToAccept: any = [
        { width: '28%', name: this.TranslatePipe.transform('admincompanies_th_name'), code: 'name', sortable: false },
        { width: '16%', name: this.TranslatePipe.transform('admincompanies_th_city'), code: 'city', sortable: false },
        { width: '20%', name: this.TranslatePipe.transform('admincompanies_th_type'), code: 'type', sortable: false },
        {
            width: '14%',
            name: this.TranslatePipe.transform('admincompanies_th_status'),
            code: 'statusDisplay',
            sortable: false
        },
        { width: '', name: '', code: 'actions' }
    ];

    constructor(
        public formatTsPipe: formatTsPipe,
        private DefaultService: DefaultService,
        private Router: Router,
        public HelpersService: HelpersService,
        private SearchService: SearchService,
        private Route: ActivatedRoute,
        private toastr: ToastrService,
        private AuthenticationService: AuthenticationService
    ) {
        this.Route.queryParams.subscribe((params: any) => {
            const parameters = Object.assign({}, params);
            if (parameters.id) delete parameters['id'];
            if (JSON.stringify(parameters) === JSON.stringify(this.previousParams)) {
                return;
            }
            this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
            this.SEARCH = parameters.search
                ? this.getInitialSearch(JSON.parse(parameters.search))
                : this.getInitialSearch();
            this.SORT = parameters.sort ? JSON.parse(parameters.sort) : this.getInitialSort();
            let code = undefined;
            if (parameters.page !== this.previousParams?.page || this.previousParams?.page !== undefined)
                code = 'changeStartRow';
            this.getItems(code);
            this.previousParams = parameters;
        });
    }

    ngOnInit(): void {
        this.AuthenticationService.getUser().subscribe((next: any) => {
            if (next) {
                this.user = { ...next };
            }
        });
    }

    getInitialSearch(SEARCH?: any) {
        if (SEARCH) {
            return {
                ...this.getDefaultSearch(),
                ...SEARCH
            };
        } else return this.getDefaultSearch();
    }

    getDefaultSearch() {
        return {};
    }

    getInitialSort() {
        return {
            code: 'lastInputTS',
            dir: 'desc'
        };
    }

    clickSort(code: string, dir?: string) {
        this.Router.navigate([], {
            queryParams: {
                sort: JSON.stringify(
                    this.SearchService.getCleanSort(
                        this.SearchService.getSort(code, this.SORT, dir),
                        this.getInitialSort()
                    )
                )
            },
            queryParamsHandling: 'merge'
        });
    }

    getItems(code?: string, type?: string) {
        // code: changestartrow
        // type: items | items_to_accept
        const observables$: Observable<any>[] = [of(null)];
        // ITEMS
        if (!type || type === 'items') {
            const SORT = { ...this.SORT };
            const SEARCH = this.SearchService.getSearchBody(this.SEARCH, SORT, this.RPP, this.startRow);
            this.tableLoading = true;
            const items$ = this.DefaultService.companiesGetAllCompanies(
                SEARCH.startRow,
                SEARCH.RPP,
                SEARCH.orderBy,
                SEARCH.term,
                SEARCH.species,
                SEARCH.activity,
                SEARCH.status ? [SEARCH.status] : [1, 3, 4]
            ).pipe(
                tap((next: any) => {
                    this.items = this.getFormattedItems(next.data);
                    this.totalRows = next.rows;
                    this.tableLoading = false;
                    if (code === 'changeStartRow') {
                        document.getElementById('items').scrollIntoView();
                    }
                })
            );
            observables$.push(items$);
        }

        // ITEMS_TO_ACCEPT
        if ((!type || type === 'items_to_accept') && !this.companiesToAcceptLoaded) {
            this.companiesToAcceptLoaded = true;
            this.tableLoadingToAccept = true;
            const itemsToAccept$ = this.DefaultService.companiesGetAllCompanies(
                this.startRowToAccept,
                this.RPPToAccept,
                ['createTS desc'],
                undefined,
                undefined,
                undefined,
                [2]
            ).pipe(
                tap((next: any) => {
                    this.itemsToAccept = this.getFormattedItems(next.data);
                    this.totalRowsToAccept = next.rows;
                    this.tableLoadingToAccept = false;
                    if (code === 'changeStartRow') {
                        document.getElementById('items-to-accept').scrollIntoView();
                    }
                    this.HelpersService.getAdminCompaniesToAccept(next.rows);
                })
            );
            observables$.push(itemsToAccept$);
        }

        forkJoin(observables$).subscribe(() => {
            this.ready = true;
        });
    }

    search = (reset?: boolean, type?: string) => {
        if (reset) {
            this.Router.navigate([], {
                queryParams: {
                    search: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.Router.navigate([], {
                queryParams: {
                    search: JSON.stringify(this.SearchService.getCleanSearch(this.SEARCH)),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
    };

    getFormattedItems(items) {
        return items.map((item) => {
            return this.getFormattedItem(item);
        });
    }

    getFormattedItem(item) {
        let status = this.TranslatePipe.transform('status_1');
        switch (item.status) {
            case 1:
                status = this.TranslatePipe.transform('status_1');
                break;
            case 2:
                status = this.TranslatePipe.transform('status_2');
                break;
            case 3:
                status = this.TranslatePipe.transform('status_3');
                break;
            case 4:
                status = this.TranslatePipe.transform('status_4');
                break;
        }
        item.statusDisplay = status;
        // to accept
        if (item.status === 2) {
            item.actions = {
                type: 'actions',
                actions: [
                    {
                        name: this.TranslatePipe.transform('admincompanies_accept'),
                        code: 'accept',
                        icon: 'plus'
                    },
                    {
                        name: this.TranslatePipe.transform('admincompanies_decline'),
                        code: 'decline',
                        icon: 'trash',
                        class: 'delete-red'
                    }
                ]
            };
        } else {
            if (item.lastInputTS) {
                item.lastInputTS = `${this.formatTsPipe.transform(
                    item.lastInputTS,
                    'D MMM YYYY'
                )} ${this.TranslatePipe.transform('misc_date_at')} ${this.formatTsPipe.transform(
                    item.lastInputTS,
                    'HH:mm'
                )}`;
            } else item.lastInputTS = '-';
        }
        item = {
            ...item,
            name: { type: 'title', title: item.company },
            city: item.city,
            type: {
                type: 'titleStampArray',
                titles: item?.activities?.map((act) => {
                    return {
                        value: this.TranslatePipe.transform('activity_' + act.activity),
                        color: '#909090',
                        stamps: [
                            {
                                value: this.TranslatePipe.transform('species_' + act.species)
                            }
                        ]
                    };
                })
            }
        };
        return item;
    }

    tableClick(item: any, head: string) {
        this.Router.navigate([this.HelpersService.getUrl('admin/companies/' + item.id)]);
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'accept':
                this.tableLoading = true;
                this.tableLoadingToAccept = true;
                this.DefaultService.companiesChangeCompanyStatus({ status: '1' }, item.id).subscribe((next) => {
                    this.toastr.success(
                        this.TranslatePipe.transform('toast_company_accepted_msg'),
                        this.TranslatePipe.transform('toast_company_accepted_title')
                    );
                    this.getItems();
                });
                break;
            case 'decline':
                this.tableLoadingToAccept = true;
                this.DefaultService.companiesChangeCompanyStatus({ status: '4' }, item.id).subscribe((next) => {
                    this.toastr.success(
                        this.TranslatePipe.transform('toast_company_declined_msg'),
                        this.TranslatePipe.transform('toast_company_declined_title')
                    );
                    this.getItems();
                });
                break;
        }
    }

    resetStartRow() {
        this.startRow = 0;
        this.getItems();
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.getItems('changeStartRow', 'items');
    }

    changeStartRowToAccept(startRow: number) {
        this.startRowToAccept = startRow;
        this.getItems('changeStartRow', 'items_to_accept');
    }
}
