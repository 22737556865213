import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe, formatTsPipe } from '../../../utils/pipes';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../../services/resource.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { HelpersService } from '../../../services/helpers.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultService } from '../../../utils/api';
import { forkJoin, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RegistrationHistoryDetailComponent } from '../../registration-history-detail/registration-history-detail.component';

@Component({
    selector: 'app-admin-company',
    templateUrl: './admin-company.component.html',
    styleUrls: ['./admin-company.component.scss'],
    standalone: false
})
export class AdminCompanyComponent implements OnInit {
    saving: boolean = false;
    ready: boolean = false;
    id: any = null;

    company: any = { company: null, user: null };
    user: any;

    tabs: ('registrations' | 'company')[] = ['registrations', 'company'];
    activeTab: 'registrations' | 'company' = 'registrations';

    history: any[] = null;

    RPP: number = 20;
    startRow: number = 0;
    totalRows: number = null;
    tableLoading: boolean = true;

    SEARCH: { dateFrom?: string; dateTo?: string } = {};

    exportBusy: boolean = false;

    tableHeads: any = [
        { name: this.TranslatePipe.transform('registrationhistory_year'), code: 'year', sortable: false, width: '25%' },
        {
            name: this.TranslatePipe.transform('registrationhistory_week_nr'),
            code: 'weekNr',
            sortable: false,
            width: '25%'
        },
        {
            name: this.TranslatePipe.transform('registrationhistory_name'),
            code: 'editedBy',
            sortable: false,
            width: '25%'
        },
        {
            name: this.TranslatePipe.transform('registrationhistory_edited_on'),
            code: 'editedOn',
            sortable: false,
            width: '25%'
        }
    ];

    constructor(
        private Router: Router,
        private ResourceService: ResourceService,
        private TranslatePipe: TranslatePipe,
        public HelpersService: HelpersService,
        private AuthenticationService: AuthenticationService,
        private toastr: ToastrService,
        private DefaultService: DefaultService,
        private Route: ActivatedRoute,
        public formatTsPipe: formatTsPipe,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.id = this.Route.snapshot.params['id'];
        this.AuthenticationService.getUser().subscribe((next: any) => {
            if (next) {
                this.user = { ...next };
            }
        });
        this.init();
    }

    init() {
        const observables$: Observable<any>[] = [of(null)];

        const company$ = this.DefaultService.companiesGetSingle(this.id).pipe(
            tap((next: any) => {
                this.company.company = next;
                if (!next.federationIds) next.federationIds = [];
                next.federationIds = next?.federationIds.map((item) => {
                    return item.fedId;
                });
                this.company.company.federations = this.ResourceService.getUserFederations(next)
                    .map((item) => {
                        return item.name;
                    })
                    .join(', ');
            })
        );
        observables$.push(company$);

        const user$ = this.DefaultService.usersGetAll(0, 1, undefined, undefined, undefined, this.id, true).pipe(
            tap((next: any) => {
                if (next?.data?.length) this.company.user = next.data[0];
            })
        );
        if (this.user.company.type !== 'gov') {
            observables$.push(user$);
        }

        if (this.user.company.type === 'gov') {
            this.getHistory();
        }

        forkJoin(observables$).subscribe(() => {
            this.ready = true;
        });
    }

    showStatus() {
        return ['2', '3', '4'].indexOf(this.company?.company.status.toString()) != -1;
    }

    setStatus(status) {
        status = status.toString();
        this.DefaultService.companiesChangeCompanyStatus({ status: status }, this.id).subscribe((next) => {
            this.toastr.success(
                this.TranslatePipe.transform('toast_company_saved_msg'),
                this.TranslatePipe.transform('toast_company_saved_title')
            );
            this.init();
            this.HelpersService.getAdminCompaniesToAccept();
        });
    }

    getHistory() {
        const searchSTR = this.getSearchQuery();
        this.tableLoading = true;
        this.DefaultService.dataInputGetDataInputHistoryList(
            searchSTR.rRP,
            searchSTR.startRow,
            this.id,
            this.SEARCH.dateFrom,
            this.SEARCH.dateTo
        ).subscribe((next: any) => {
            if (this.startRow && this.startRow >= next.rows) {
                this.resetStartRow();
                return;
            }
            this.history = next.data.map((item) => {
                const actions = [];
                return {
                    ...item,
                    editedBy: item.name,
                    editedOn: `${this.formatTsPipe.transform(
                        item.editTS,
                        'D MMM YYYY'
                    )} ${this.TranslatePipe.transform('misc_date_at')} ${this.formatTsPipe.transform(
                        item.editTS,
                        'HH:mm'
                    )}`
                };
            });
            this.tableLoading = false;
            this.totalRows = next.rows;
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        });
    }

    tableClick(item: any, head: string) {
        // if (item.editable) {
        //     this.Router.navigate([this.HelpersService.getUrl('new')]);
        // } else this.Router.navigate([this.HelpersService.getUrl(`registration-history/${item.year}/${item.weekNr}`)]);
        const modalRef = this.modalService.open(RegistrationHistoryDetailComponent, {
            windowClass: 'modal--1150'
        });
        modalRef.componentInstance.govYear = item.year;
        modalRef.componentInstance.govWeek = item.weekNr;
        modalRef.componentInstance.govCompanyId = this.id;
        modalRef.componentInstance.dismiss.subscribe(() => modalRef.dismiss());
    }

    resetStartRow() {
        this.startRow = 0;
        this.getHistory();
    }

    getSearchQuery() {
        return {
            startRow: this.startRow,
            rRP: this.RPP
        };
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.getHistory();
    }

    export() {
        this.exportBusy = true;
        // this.DefaultService.subscriptionsExportAll().subscribe((data) => {
        setTimeout(() => {
            this.exportBusy = false;
        }, 500);
        // });
        window.location.href = `/api/data-input/export/${this.id}`;
    }
}
