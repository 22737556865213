import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../utils/pipes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../services/resource.service';
import { AuthenticationService } from '../../services/authentication.service';
import { UsersService } from '../../services/users.service';
import { HelpersService } from '../../services/helpers.service';
import { TranslatorService } from '../../services/translator.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

declare const grecaptcha: any;

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: false
})
export class ForgotPasswordComponent implements OnInit {
    id: string = '';
    hash: string = '';
    formSTR: any = {};
    validation: any = {};
    saving: boolean = false;
    ready: boolean = false;
    pwdIsValid: boolean = false;
    changes: boolean = false;

    constructor(
        public TranslatePipe: TranslatePipe,
        private ResourceService: ResourceService,
        public HelpersService: HelpersService,
        private UsersService: UsersService,
        private Route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.id = this.Route.snapshot.params['id'];
        this.hash = this.Route.snapshot.params['hash'];

        // get user hash
        this.UsersService.getUserForHash(this.id, this.hash).subscribe({
            next: (next) => {
                setTimeout(() => {
                    this.formSTR = {
                        password: ''
                    };
                    this.ready = true;
                }, 500);
            },
            error: (error) => {
                window.location.href = `${this.HelpersService.getUrl('', 'home')}?msg=invalidlink`;
            }
        });
    }

    submit() {
        let FORM = { ...this.formSTR };
        let that = this;
        this.saving = true;
        grecaptcha.ready(function () {
            grecaptcha.execute('6LfXbsQaAAAAAIpbJZelj4SW37Md5HVBNHvP0kns', { action: 'submit' }).then(function (token) {
                that.UsersService.resetPassword(that.id, that.hash, FORM, token).subscribe({
                    next: (next) => {
                        that.changes = false;
                        that.validation = {};
                        that.saving = false;
                        window.location.href = `${that.HelpersService.getUrl('')}?msg=passwordreset`;
                    },
                    error: (error) => {
                        if (error.error.error && error.error.error == 'captcha_verification') {
                            that.validation = {
                                user: {},
                                company: {},
                                captcha: 'generic_error_captcha_score'
                            };
                            return;
                        }
                        that.validation = error.error.details;
                        that.saving = false;
                    }
                });
            });
        });
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
