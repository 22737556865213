import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { TranslatorService } from '../services/translator.service';
import { TranslatePipe } from '../utils/pipes';
import pkg from '../../../package.json';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private toastr: ToastrService,
        private router: Router,
        private AuthenticationService: AuthenticationService,
        private TranslatorService: TranslatorService,
        private TranslatePipe: TranslatePipe
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let clonedRequest = request.clone({
            headers: request.headers.append('Accept-Language', this.TranslatorService.getCurrentLanguage())
        });
        if (clonedRequest.method == 'GET') {
            const versionParam = `${pkg.version}-${this.TranslatorService.getCurrentLanguage()}`;
            clonedRequest = request.clone({
                setParams: {
                    v: versionParam
                },
                headers: request.headers.append('Accept-Language', this.TranslatorService.getCurrentLanguage())
            });
        }
        function failwhale(error) {
            const msg = `${error.error}`;
            if (document.getElementsByClassName('page') && document.getElementsByClassName('page').length) {
                document.getElementsByClassName('page')[0].innerHTML = msg;
            } else {
                document.getElementsByTagName('BODY')[0].innerHTML = msg;
            }
            // modals
            if (
                document.getElementsByClassName('modal-backdrop') &&
                document.getElementsByClassName('modal-backdrop').length
            ) {
                document.getElementsByClassName('modal-backdrop')[0].setAttribute('style', 'display:none !important');
            }
            if (document.getElementsByClassName('modal') && document.getElementsByClassName('modal').length) {
                document.getElementsByClassName('modal')[0].setAttribute('style', 'display:none !important');
            }
            if (document.getElementsByClassName('modal-open') && document.getElementsByClassName('modal-open').length) {
                document.getElementsByClassName('modal-open')[0].setAttribute('style', 'overflow:unset !important');
            }
            // footer
            if (document.getElementsByClassName('newsletter') && document.getElementsByClassName('newsletter').length) {
                document.getElementsByClassName('newsletter')[0].setAttribute('style', 'display:none !important');
            }
            if (document.getElementsByClassName('footer') && document.getElementsByClassName('footer').length) {
                document.getElementsByClassName('footer')[0].setAttribute('style', 'display:none !important');
            }
        }
        return next.handle(clonedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 400:
                        // bad request / validation
                        if (error.error && error.error.details && typeof error.error.details == 'string') {
                            this.toastr.error(error.error.details, 'Fout');
                        } else {
                            // this.toastr.error('Je hebt één of meer fouten', 'Fout');
                            this.toastr.error(
                                this.TranslatePipe.transform('toast_validation_error_msg'),
                                this.TranslatePipe.transform('toast_validation_error_title')
                            );
                        }
                        break;
                    case 504:
                        this.redirectToLogin();
                        break;
                    case 401:
                        // unauthorized
                        this.redirectToLogin();
                        break;
                    case 500:
                        // internal server error
                        // this.toastr.error('Our team is notified and working on a solution', 'An error occured');
                        failwhale(error);
                        break;
                    case 403:
                        // forbidden
                        // this.toastr.error('You do not have access to this page', 'Forbidden');
                        failwhale(error);
                        break;
                    case 404:
                        // not found
                        // this.toastr.error('Page not found', '404');
                        failwhale(error);
                        break;
                }
                return throwError(error);
            })
        );
    }

    redirectToLogin() {
        this.AuthenticationService.onUnauth();
    }
}
