import { Component, OnInit, inject } from '@angular/core';
import { TranslatePipe } from '../../utils/pipes';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    standalone: false
})
export class PrivacyPolicyComponent implements OnInit {
    TranslatePipe = inject(TranslatePipe);
    content: string = this.TranslatePipe.transform('privacypolicy_content');

    constructor(public Route: ActivatedRoute) {}

    ngOnInit(): void {}
}
