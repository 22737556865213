import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from '../../../services/helpers.service';
import { ResourceService } from '../../../services/resource.service';

@Component({
    selector: 'app-register-success',
    templateUrl: './register-success.component.html',
    styleUrls: ['./register-success.component.scss'],
    standalone: false
})
export class RegisterSuccessComponent implements OnInit {
    allowInputIndex: boolean = false;

    constructor(
        public ActiveModal: NgbActiveModal,
        public HelpersService: HelpersService,
        public ResourceService: ResourceService
    ) {}

    ngOnInit(): void {
        if (this.ResourceService.getWeekInfo().input.active) {
            this.allowInputIndex = true;
        }
    }

    toNewIndex() {
        window.location.href = this.HelpersService.getUrl('new');
    }
    toHome() {
        window.location.href = this.HelpersService.getUrl('', 'home');
    }
}
