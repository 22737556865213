import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { TranslatorService } from '../../../services/translator.service';
import { UsersService } from '../../../services/users.service';
import { ResourceService } from '../../../services/resource.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '../../../utils/pipes';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
    standalone: false
})
export class AddUserComponent implements OnInit {
    @Output() created = new EventEmitter();
    TranslatorService = inject(TranslatorService);
    ResourceService = inject(ResourceService);
    formSTR: any = { email: '', language: this.TranslatorService.getCurrentLanguage() };
    validation: any = {};
    languageOptions: any[] = this.ResourceService.getLanguages();
    saving: boolean = false;

    constructor(
        private UsersService: UsersService,
        public ActiveModal: NgbActiveModal,
        private TranslatePipe: TranslatePipe,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {}

    submit() {
        this.saving = true;
        let FORM = { ...this.formSTR };
        this.UsersService.inviteUser(FORM).subscribe({
            next: (next) => {
                this.validation = {};
                this.saving = false;
                this.toastr.success(
                    this.TranslatePipe.transform('toast_invite_user_success_msg'),
                    this.TranslatePipe.transform('toast_invite_user_success_title')
                );
                this.created.emit();
                this.ActiveModal.dismiss();
            },
            error: (error) => {
                this.validation = error.error.details || {};
                this.saving = false;
            }
        });
    }
}
