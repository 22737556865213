import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';
import { IndexService } from '../../services/index.service';
import { ResourceService } from '../../services/resource.service';
import { DefaultService } from '../../utils/api';

@Component({
    selector: 'app-slaughterhouse-monitor',
    templateUrl: './slaughterhouse-monitor.component.html',
    styleUrls: ['./slaughterhouse-monitor.component.scss'],
    standalone: false
})
export class SlaughterhouseMonitorComponent implements OnInit {
    indexType: 'pork' | 'beef';
    monitor: any = null;
    ready: boolean = false;
    weekInfo: any;

    speciesPartCodes: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private defaultService: DefaultService,
        private resourceService: ResourceService,
        public indexService: IndexService,
        public helpersService: HelpersService
    ) {}

    ngOnInit(): void {
        this.indexType = this.route.snapshot.params.indexType;
        this.speciesPartCodes = this.resourceService.getSpeciesPartsCodes();
        if (!this.speciesPartCodes.includes(this.indexType)) {
            this.router.navigate(['../'], { relativeTo: this.route });
            this.indexType = 'pork';
        }

        this.weekInfo = this.resourceService.getWeekInfo();
        this.init();
    }

    init() {
        this.defaultService.monitorGetMonitor().subscribe((next) => {
            this.monitor = next;
            this.ready = true;
            // // tests
            // this.monitor.weekData[0].previous.diff_gemiddeld_warmgewicht = null;
            // this.monitor.weekData[0].current.gemiddeld_warmgewicht = null;
        });
    }

    selectTable(type: 'pork' | 'beef') {
        if (this.indexType === type) return;
        this.indexType = type;
        this.router.navigate(['../' + this.indexType], { relativeTo: this.route });
        this.init();
    }
}
