import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-field-select',
    templateUrl: './field-select.component.html',
    styleUrls: ['./field-select.component.scss'],
    standalone: false
})
export class FieldSelectComponent implements OnInit {
    @Input() label?: string;
    @Input() arrowFit?: string;
    @Input() hideLabel?: boolean;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() placeholder?: string;
    @Input() options: { title: string; value: any }[];
    @Input() model: string;
    @Output() modelChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        if (this.placeholder) {
            this.options = [{ title: this.placeholder, value: undefined }, ...this.options];
        }
    }

    getSelected() {
        if (!this.model && !this.placeholder) {
            return;
        } else if (!this.model && this.placeholder) {
            return this.placeholder;
        } else {
            const index = this.options
                .map((item) => {
                    return item.value;
                })
                .indexOf(this.model);
            return this.options[index].title;
        }
    }
}
