import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../utils/pipes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../services/resource.service';
import { AuthenticationService } from '../../services/authentication.service';
import { HelpersService } from '../../services/helpers.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: false
})
export class ProfileComponent implements OnInit {
    formSTR: any = {
        user: {},
        company: {},
        password: {}
    };
    formSTRCopy: any = {};
    validation: any = { user: {}, company: {}, password: {} };
    saving: boolean = false;
    ready: boolean = false;
    changes: boolean = false;
    activeSection: '' | 'password' | 'company' | 'user' = '';

    constructor(
        public TranslatePipe: TranslatePipe,
        private ModalService: NgbModal,
        private Router: Router,
        private ResourceService: ResourceService,
        public HelpersService: HelpersService,
        private AuthenticationService: AuthenticationService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.init();
        if (this.HelpersService.getParam('toast') && this.HelpersService.getParam('toast') == 'success') {
            this.successToast();
            this.HelpersService.removeParam('toast');
        }
    }

    init() {
        const user: any = this.AuthenticationService.getUserValue();
        this.formSTR = {
            user: {
                language: user.language,
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                mobile: user.mobile
            },
            company: {
                company: user.company.company,
                vat: user.company.vat,
                street: user.company.street,
                streetNr: user.company.streetNr,
                streetBox: user.company.streetBox,
                zipcode: user.company.zipcode,
                city: user.company.city,
                phone: user.company.phone,
                federations: this.ResourceService.getUserFederations(user)
                    .map((item) => {
                        return item.name;
                    })
                    .join(', ')
            },
            password: {
                oldPassword: '',
                newPassword: ''
            }
        };
        this.formSTRCopy = JSON.parse(JSON.stringify(this.formSTR));
        this.ready = true;
    }

    successToast() {
        this.toastr.success(
            this.TranslatePipe.transform('toast_update_user_success_msg'),
            this.TranslatePipe.transform('toast_update_user_success_title')
        );
    }

    saveSection(type: 'user' | 'password') {
        this.saving = true;
        let FORM;
        switch (type) {
            case 'user':
                FORM = this.formSTR.user;
                this.AuthenticationService.updateUser(FORM).subscribe({
                    next: (next) => {
                        this.AuthenticationService.fetchUser().subscribe((next) => {
                            this.validation = { user: {}, company: {}, password: {} };
                            if (FORM.language != this.formSTRCopy.user.language) {
                                this.HelpersService.addParam('toast', 'success');
                                setTimeout(() => {
                                    const newUrl = window.location.href.replace(
                                        `/${this.formSTRCopy.user.language}`,
                                        `/${FORM.language || 'nl'}`
                                    );
                                    window.location.href = newUrl;
                                }, 1);
                            } else {
                                this.saving = false;
                                this.activeSection = '';
                                this.successToast();
                                this.init();
                            }
                        });
                    },
                    error: (error) => {
                        this.validation = { user: {}, company: {}, password: {} };
                        this.validation.user = error.error.details || {};
                        this.saving = false;
                    }
                });
                break;
            case 'password':
                FORM = this.formSTR.password;
                this.AuthenticationService.updateUserPassword(FORM).subscribe({
                    next: (next) => {
                        this.validation = { user: {}, company: {}, password: {} };
                        this.saving = false;
                        this.activeSection = '';
                        this.toastr.success(
                            this.TranslatePipe.transform('toast_update_password_success_msg'),
                            this.TranslatePipe.transform('toast_update_password_success_title')
                        );
                    },
                    error: (error) => {
                        this.validation = { user: {}, company: {}, password: {} };
                        this.validation.password = error.error.details || {};
                        this.saving = false;
                    }
                });
                break;
        }
    }

    // submit() {
    //     let FORM = JSON.parse(JSON.stringify(this.formSTR));
    //     if (FORM.company.phone && FORM.company.phone == '') {
    //         delete FORM.company.phone;
    //     }
    //     if (FORM.user.mobile && FORM.user.mobile == '') {
    //         delete FORM.user.mobile;
    //     }
    //     this.saving = true;
    // }

    editSection(section) {
        this.validation = { user: {}, company: {}, password: {} };
        this.formSTR = JSON.parse(JSON.stringify(this.formSTRCopy));
        if (!section) {
            this.activeSection = '';
        } else {
            this.activeSection = section;
        }
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
